<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">
                  Xarajatga oborilmagan kumlik Amortizatsilayalar (transport)
                </h3>
              </div>
              <div class="card-button">
                <v-btn to="/incomes/create" color="primary" small> + </v-btn>
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :items="list"
                :loading="isLoading"
                :items-per-page="10"
                hide-default-footer
                single-select
                @click:row="rowClick"
                item-key="id"
                no-data-text="Malumot kiritilmagan"
                class="row-pointer"
              >
                <template v-slot:[`item.income_type`]="{ item }">
                  <div v-if="item.income_type_description !== null">
                    {{ item.income_type_description.name }}
                  </div>
                </template>
                <template v-slot:[`item.oper_date`]="{ item }">
                  {{ item.oper_date | formatDate }}
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div style="text-align: right !important">
                    <action :index="item"></action>
                  </div> </template
              ></v-data-table>
            </div>
            <!-- <div class="text-left my-5">
              <v-pagination
                v-model="currentPage"
                :total-visible="10"
                @input="getPostData(currentPage)"
                :length="Math.ceil(getCount.count / 10)"
              ></v-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import action from './Action'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  //   components: { action },
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      perPage: 10,
      currentPage: 1,
      headers: [
        {
          text: 'Sana',
          value: 'oper_date'
        },
        {
          text: 'Summa',
          value: 'operation_amount'
        },
        {
          text: 'Asosiy vosita',
          value: 'main_assets'
        },
        {
          text: '',
          value: 'action'
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('getDailyAmortizationMainAssets')
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    list() {
      const data = this.$store.state.requests.dailyAmortizationMainAssets
      return data
    },
    getCount() {
      return this.$store.state.requests.incomeList
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: ' Xarajatga oborilmagan kumlik Amortizatsilayalar (transport)' }
    ])
  }
}
</script>
